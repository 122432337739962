import React, { useEffect } from 'react';
import olympus_home_1 from '../assets/images/Olympus-Home.jpg';
import './Home.css';

const Home = () => {
    return (
        <div id="Olympus_Home" className="container-fluid">HOME</div>
    )
}

export default function () {
    return (
        <Home />
    );
}