import React from 'react';
import { createRoot } from 'react-dom/client';

//Global libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/fontawesome-free-5.15.2-web/css/all.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

//Component
import { App } from './components/Layout';
const container = document.getElementById('OLYMPUS_ROOT');
const root = createRoot(container);
root.render(
    <App />
);

