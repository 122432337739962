import create from 'zustand';

const useStore = create(set => ({
    //API URL
    APIURL: "https://techitem.net/",
    /*APIURL: "https://localhost:5001/",*/
    //APIURL: "http://192.168.0.13:25944/",
    APPSCREEN: "DASHBOARD",
    AUTHENTICATED: false,
    USER: {
        USERID: '',
        EMAIL: '',
        FIRSTNAME: '',
        LASTNAME: ''
    },
    SYSTEMINFO:[],
    toggleOption: (obj) => set(state => ({ ...state, ...obj }))
}));

export default useStore;