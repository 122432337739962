import React, { useEffect } from "react";
import axios from "axios";
import useStore from "./Store";
import ClientService from "./ClientService.js";
import olympus_home_1 from "../assets/images/Olympus-Home.jpg";
import "./Dashboard.css";

const Dashboard = () => {
  const context = useStore();

  function setActionRequest(action) {
    const token = ClientService.getKeyFromStorage();

    axios
      .post(
        context.APIURL + "Action/setaction",
        {
          //0 - Start Motor, 1 - Stop Motor
          ActionType: action,
          //0 - Waiting, 1- Done
          ActionState: 0,
          DeviceId: "WIFI_CONSERVATORY_1",
          //0 - Motor
          DeviceType: 0,
          SensorValue: null,
          DateCreated: new Date()
            .toLocaleString("en", {
              hour12: false,
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
            .replace(
              /^(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/,
              "$1/$2/$3 $4:$5"
            ),
          DateModified: new Date()
            .toLocaleString("en", {
              hour12: false,
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
            .replace(
              /^(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/,
              "$1/$2/$3 $4:$5"
            ),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const r = context.SYSTEMINFO;
        r.push(response.data);
        context.toggleOption({ SYSTEMINFO: r });
      });
  }

  function getActionRequest() {}

  return (
    <div id="Olympus_DashBoard" className="container-fluid p-0">
      <div className="row m-0 h-100">
        <div id="Olympus_SideBar" className="col-sm-2 p-3">
          <div className="col-md-12 Olympus_SideBar_Element">PROJECT</div>
        </div>
        <div id="Olympus_MainContainer" className="col-sm-10 px-3">
          <div className="row mx-0 my-2">
            <div className="col-md-12 Olympus_MainContainer_Content color_black">
              MY HOUSE
            </div>
          </div>
          <div className="row mx-0 my-2">
            <div className="col-md-6 Olympus_MainContainer_Division">
              <div className="row m-0 py-2 color_black">CONSERVATORY</div>
              <div className="row m-0 Olympus_MainContainer_Division_Container">
                <div className="col-md-12 color_black Olympus_MainContainer_Division_Device_System">
                  <div className="row m-0">
                    <div className="col-md-8">WATER SYSTEM</div>
                    <div className="col-md-4 text-right">
                      <button
                        className="Olympus_MainContainer_Division_Button_Info font-size-16 p-2"
                        onClick={() => setActionRequest(0)}
                      >
                        System Current State
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 pt-2">
                  <div className="row m-0">
                    <div className="col-md-4">
                      <button
                        className="col-md-12 Olympus_MainContainer_Division_Button_Success font-size-16"
                        onClick={() => setActionRequest(0)}
                      >
                        START MOTOR
                      </button>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="col-md-12 Olympus_MainContainer_Division_Button_Stop font-size-16"
                        onClick={() => setActionRequest(1)}
                      >
                        STOP MOTOR
                      </button>
                    </div>
                    <div className="col-md-4">
                      <button className="col-md-12 Olympus_MainContainer_Division_Button_Info font-size-16">
                        SENSOR VALUE
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 pt-2">
                  <div className="row m-0">
                    <div className="col-md-12 Olympus_MainContainer_Division_System_Information">
                      System Information
                    </div>
                  </div>
                  <div className="row m-0 Olympus_MainContainer_Division_System_Information_Panel">
                    {context.SYSTEMINFO.map((si) => (
                      <div
                        key={si.resultId}
                        className="col-md-12 py-1 Olympus_MainContainer_Division_System_Information_Panel_Content"
                      >
                        {si.resultMessage}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function () {
  return <Dashboard />;
}
