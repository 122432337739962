import axios from "axios";
import React, { useEffect, useState } from 'react';
import useStore  from "./Store";
import ClientService from "./ClientService.js";
import Home from "./Home";
import Dashboard from "./Dashboard";
import './Layout.css';
import olympus_icon_1 from '../assets/images/Olympus-Icon.png';


const Layout = () => {
    const context = useStore();

    return (
        <div id="Olympus_MainLayout" className='bg-container'>
            <Header />
            <div id="Olympus_Body">
                {context.AUTHENTICATED == false && context.APPSCREEN == "LOGIN" && <Login />}

                {context.AUTHENTICATED == false && context.APPSCREEN == "HOME" && <Home />}
                {context.AUTHENTICATED == true && context.APPSCREEN == "DASHBOARD" && <Dashboard />}
                

                <div className="shader">
                    <div className='bgimg-1'></div>
                    <div className="shader__layer specular">
                        <div className="shader__layer mask"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const Header = () => {
    const context = useStore();

    return (
        <div id="Olympus_Header">
            <nav className="navbar navbar-expand-lg navbar-light navbar-dark px-4">
                <a className="navbar-brand" href="#">
                    <img src={olympus_icon_1} height="65" alt="" />
                    <h1 className="pr-2 m-0">OLYMPUS</h1>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        {
                            context.AUTHENTICATED === false && <li className="nav-item active">
                                <button className="nav-link bg-transparent"
                                    onClick={() => context.toggleOption({ APPSCREEN: "LOGIN" })}>
                                    Login
                                </button>
                            </li>
                        }

                        {
                            context.AUTHENTICATED === true && <li className="nav-item active">
                                <div className="nav-link bg-transparent">
                                    Hello, {context.USER.FIRSTNAME + " " + context.USER.LASTNAME } 
                                </div>
                            </li>
                        }

                        {
                            context.AUTHENTICATED === true && <li className="nav-item active">
                                <button className="nav-link bg-transparent"
                                    onClick={() => context.toggleOption({ APPSCREEN: "LOGIN" })}>
                                    Logout
                                </button>
                            </li>
                        }


                    </ul>
                </div>
            </nav>
        </div>
    )
}

const Login = () => {
    const context = useStore();

    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    function loginRequest() {
        axios.post(context.APIURL + 'Account/signin', {
            Email: inputs.Email,
            Password: inputs.Password
        }).then((response) => {
            const r = response.data;

            ClientService.addKeyToStorage(r.token);
            context.toggleOption({ APPSCREEN: "DASHBOARD",AUTHENTICATED : true, USER:{ USERID : r.userId, EMAIL : r.email, FIRSTNAME: r.firstName, LASTNAME : r.lastName } });
        });
    }

    return (

        <div id="loginModal" className="modal-dialog modal-lg mx-auto">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title p-2">Login</h2>
                    <button type="button" className="btn-close btn-close-white p-2" aria-label="Close" onClick={() => context.toggleOption({ APPSCREEN: "HOME" })}>
                    </button>
                </div>
                <div className="modal-body p-2">
                    <form>
                        <div className="form-group row py-2">
                            <label className="col-sm-2 col-form-label">Email</label>
                            <div className="col-sm-10">
                                <input className="form-control"
                                    type="text"
                                    name="Email"
                                    value={inputs.Email || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-sm-2 col-form-label">Password</label>
                            <div className="col-sm-10">
                                <input className="form-control"
                                    type="password"
                                    name="Password"
                                    value={inputs.Password || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer p-2">
                    <button type="submit" className="btn btn-primary" onClick={loginRequest}>Login</button>
                </div>
            </div>
        </div>

    )
}

const Footer = () => {
    const today = new Date();
    const year = today.getFullYear();

    return (
        <div className="footer-dark">
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 item">
                            <h3>Technologies</h3>
                            <ul>
                                <li><a href="#">IOT</a></li>
                                <li><a href="#">WEB</a></li>
                            </ul>
                        </div>

                        <div className="col-md-9 item text">
                            <h3>Olympus</h3>
                            <p>Smart home project</p>
                        </div>
                        <div className="col item social"><a href="#"><i className="icon ion-social-facebook"></i></a><a href="#"><i className="icon ion-social-twitter"></i></a><a href="#"><i className="icon ion-social-snapchat"></i></a><a href="#"><i className="icon ion-social-instagram"></i></a></div>
                    </div>
                    <p className="copyright">© {year} techitem</p>
                </div>
            </footer>
        </div>
    )
}

export function App() {
    return (
        <Layout />
    );
}

