import axios from "axios";

const ClientService = {

    addKeyToStorage: function(jwtToken) {
        //inspect the value
        window.sessionStorage.setItem("OlympusJWT", jwtToken);
    },

    getKeyFromStorage: function() {
        return window.sessionStorage.getItem("OlympusJWT");
    },

    getAxiosHeaders: function(){

        const headers = {
            'origin': '*',
            'authorization' : "Bearer " + this.getKeyFromStorage(),
            'Accept' : 'application/json',
            'Content-Type' : 'application/json'
        };

        return headers;
        
    },

};

export default ClientService;